<template>
    <div class="player-container">
        <div id="player" class="camera-player"></div>
    </div>
</template>

<script>
    import EZUIKit from 'ezuikit-js';

    let player = null;

    export default {
        props:["camera"],
        data(){
            return {
                playerWidth:'',
                playerHeight:"",
            }
        },
        mounted() {
            let playerElement = document.getElementById("player")
            this.playerWidth = playerElement.offsetWidth
            this.playerHeight = Math.floor(this.playerWidth * 9 / 16) + 96
            playerElement.style.height = this.playerHeight+"px"
        },
        destroyed() {
            if(player){
                player.stop()
                player=null
            }
        },
        methods:{
            playLive(){
                let code = this.camera.cameraCode
                let sn = this.camera.cameraSn
                let url = `ezopen://${code}@open.ys7.com/${sn}/1.hd.live`
                this.play(url)
            },

            play(url){
                if(player){
                    player.stop()
                    player=null
                }

                player=new EZUIKit.EZUIKitPlayer({
                    id: 'player',
                    autoplay: true,
                    url: url,
                    accessToken: this.camera.accessToken,
                    width: this.playerWidth,
                    height: this.playerHeight,
                    template:"security",
                    // footer:["fullScreen","hd","broadcast"]
                })
            },


        }
    }
</script>

<style lang="less" scoped>

.camera-player{
    height:600px;
    background: #F0f0f0;
}

    .action-block{
        display: flex;
        margin-top: 20px;

        *{
            margin-right: .8rem;
        }
    }

</style>
